import { Image, InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from "../../../../api/components";
import { requestNonceRaw, requestNonceCurl, requestNonceJava, requestNonceJavascript, requestNonceNode, requestNoncePerl, requestNoncePython, requestNoncePhp, requestNonceRuby, requestNonceGo, requestNonceC, requestNonceVB, requestNonceGroovy, requestNonceObjectiveC, requestNonceSwift, requestClientTokenRaw, requestClientTokenCurl, requestClientTokenJava, requestClientTokenJavascript, requestClientTokenNode, requestClientTokenPerl, requestClientTokenPython, requestClientTokenPhp, requestClientTokenRuby, requestClientTokenGo, requestClientTokenC, requestClientTokenVB, requestClientTokenGroovy, requestClientTokenObjectiveC, requestClientTokenSwift, requestDelegationTokenRaw, requestDelegationTokenCurl, requestDelegationTokenJava, requestDelegationTokenJavascript, requestDelegationTokenNode, requestDelegationTokenPerl, requestDelegationTokenPython, requestDelegationTokenPhp, requestDelegationTokenRuby, requestDelegationTokenGo, requestDelegationTokenC, requestDelegationTokenVB, requestDelegationTokenGroovy, requestDelegationTokenObjectiveC, requestDelegationTokenSwift } from 'requestExamples/authorization';
import { javascript, cChar } from 'requestExamples/codeSnippets';
import * as React from 'react';
export default {
  Image,
  InfoIcon,
  List,
  Table,
  Row,
  Tabs,
  Expander,
  Attributes,
  WarningIcon,
  requestNonceRaw,
  requestNonceCurl,
  requestNonceJava,
  requestNonceJavascript,
  requestNonceNode,
  requestNoncePerl,
  requestNoncePython,
  requestNoncePhp,
  requestNonceRuby,
  requestNonceGo,
  requestNonceC,
  requestNonceVB,
  requestNonceGroovy,
  requestNonceObjectiveC,
  requestNonceSwift,
  requestClientTokenRaw,
  requestClientTokenCurl,
  requestClientTokenJava,
  requestClientTokenJavascript,
  requestClientTokenNode,
  requestClientTokenPerl,
  requestClientTokenPython,
  requestClientTokenPhp,
  requestClientTokenRuby,
  requestClientTokenGo,
  requestClientTokenC,
  requestClientTokenVB,
  requestClientTokenGroovy,
  requestClientTokenObjectiveC,
  requestClientTokenSwift,
  requestDelegationTokenRaw,
  requestDelegationTokenCurl,
  requestDelegationTokenJava,
  requestDelegationTokenJavascript,
  requestDelegationTokenNode,
  requestDelegationTokenPerl,
  requestDelegationTokenPython,
  requestDelegationTokenPhp,
  requestDelegationTokenRuby,
  requestDelegationTokenGo,
  requestDelegationTokenC,
  requestDelegationTokenVB,
  requestDelegationTokenGroovy,
  requestDelegationTokenObjectiveC,
  requestDelegationTokenSwift,
  javascript,
  cChar,
  React
};