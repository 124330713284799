import { Image, InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from "../../../../api/components";
import * as React from 'react';
export default {
  Image,
  InfoIcon,
  List,
  Table,
  Row,
  Tabs,
  Expander,
  Attributes,
  WarningIcon,
  React
};